.NavbarWowmix {
    // background: transparent !important;
    color: #ddd;
    // position: absolute;
    // top: 0;
    text-align: center;
    a {
        color: #ddd;
        transition: all .5s ease-in-out;
        &:hover {
            transition: all .5s ease-in-out;
            color: #fff;
        }
    }
    .logo-navbar {
        width: 48px;
    }
}

.menu-icon {
    cursor: pointer;
}
.menu-mobile-screen {
    position: relative;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    height: 100%;
    z-index: 999;
    background: rgb(0,212,255);
    background: linear-gradient(45deg, rgba(0,212,255,1) 0%, rgba(59,149,203,1) 100%);
    
    .header-menu {
        h6 {
            span {
                width: 48px;
                cursor: pointer;
            }
        }
    }
    .path {
        background-color: rgba(255, 255, 255, .7);
        position: absolute;
        right: 0;
        top: 90px;
        clip-path: polygon(68% 27%, 100% 12%, 100% 93%, 68% 79%);
        width: 210px;
        height: 75vh;
    }
    .path2 {
        background-color: rgba(255, 255, 255, .7);
        position: absolute;
        right: 0;
        top: 55px;
        clip-path: polygon(68% 27%, 100% 12%, 100% 93%, 68% 79%);
        width: 280px;
        height: 85vh;
    }
    .path3 {
        clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
        background-color: rgba(255, 255, 255, .7);
        position: absolute;
        left: -180px;
        top: 200px;
        width: 280px;
        height: 280px;
    }
   .close-menu {
       cursor: pointer;
   }
    ul {
        li {
            margin-bottom: 20px;
            a {
                color: #fff;
                font-size: 21px;
            }
        }
    }
}