.GalleryPage {
    position: relative;
    .header-photo-gallery {
        background:  url(../../image//bg_gallery.svg) no-repeat center center;
        height: 100px;
        width: 100%;
    }
    .photos {
        img {
            height: 180px;
            border-radius: 10px;
            @media (max-width: 576px) {
                height: 100px;
            }
        }
        .large {
            // height: 385px !important;
        }
    }   
    .path-bottom-gallety{
        width: 100%;
        height: auto;
        background: rgb(65,229,232);
        background: linear-gradient(45deg, rgba(65,229,232,1) 0%, rgba(31,230,189,1) 100%);
    }
}