.gallery {
    position: relative;
    .header-photo-gallery {
        background:  url(../../../image//bg_gallery.svg) no-repeat center center;
        height: 100px;
        width: 100%;
    }
    .Coursers-item-path {
        background: url(../../../image/bg_courese_path.svg) no-repeat center center ;
        transition: all .5s ease-in-out;
        border-radius: 10px;
        height: 180px;
        width: 100%;
        background-size: cover;
    }
    .photos {
        img {
            height: 180px;
            border-radius: 10px;
              //  mobile 
        @media (max-width: 576px) {
                height: 100px;
        }
        }
        .large {
            // height: 385px !important;
        }
    }        
}