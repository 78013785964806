.coummuntiy {
    .coummuntiy-item {
        height: 220px;
    }
    position: relative;
    .clip-path-right {
        position: absolute;
        right: -30px;
        top: 0;
        z-index: -1;
        overflow-x: hidden
    }
    .clip-path-left {
        position: absolute;
        left: -30px;
        top: 0;
        z-index: -1;
        overflow-x: hidden

    }
}