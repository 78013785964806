.Coursers {
  .header-photo-gallery {
      background:  url(../../image//bg_gallery.svg) no-repeat center center;
      height: 100px;
      width: 100%;
  }
  
  .card {
    img {
      height: 102px;
    }
    h6 {
      margin-top: 20px;
    }
  }
  .Polygon_back {
    position: absolute;
    right: -330px;
    top: 10%;
  }
  .Polygon_front {
    position: absolute;
    left: -330px;
    top: 10%;
  }
}
.list-poient {
  font-size: 13px;
}
