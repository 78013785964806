

body {
    overflow-x: hidden;
    text-transform: capitalize;
}
.nav-item {
    a {
        &:hover {
            cursor: pointer;
        }
    }
}

.btn-info {
    background: rgb(0,212,255);
    background: linear-gradient(45deg, rgba(0,212,255,1) 0%, rgba(59,149,203,1) 100%);
}

  .bg-info {
        background: rgb(0,212,255) !important;
    }

.shadow {
    -webkit-box-shadow: 3px 3px 22px 0px rgba(51,51,51,0.2);
    -moz-box-shadow: 3px 3px 22px 0px rgba(51,51,51,0.2);
    box-shadow: 3px 3px 22px 0px rgba(51,51,51,0.2);
}
.shadow:hover {
    -webkit-box-shadow: 6px 6px 27px 0px rgba(55,55,55,0.2);
    -moz-box-shadow: 6px 6px 27px 0px rgba(55,55,55,0.2);
    box-shadow: 6px 6px 27px 0px rgba(55,55,55,0.2);
}

.links-contact {
        li {
            margin-bottom: 10px;
            a {
                color: #fff;
                img {
                    border: 1px solid transparent;
                    transition: all .5s ease-in-out;
                    &:hover {
                        border-radius: 50%;
                        border: 1px solid #000;
                    }
                }
            }
        }
}

.rounded {
    border-radius: 10px !important;
}

.form-control:focus {
    outline: none;
    border-color: #3B95CB;
    box-shadow: none;
}

button:hover {
    -webkit-box-shadow: 6px 6px 27px 0px rgba(55,55,55,0.2);
    -moz-box-shadow: 6px 6px 27px 0px rgba(55,55,55,0.2);
    box-shadow: 6px 6px 27px 0px rgba(55,55,55,0.2);
}

svg {
    transition: all .2s ease-in-out;
}


//  mobile 
@media (max-width: 576px) {
    .body {
        overflow-x: hidden;
    }
    .clip-path-right {
        display: none;
    }
    .clip-path-left {
        display: none;
    }
    .Polygon_back {
        display: none;
    }
    .Polygon_front {
        display: none;
    }
}

:lang(en) {
    body {
        font-family: 'Open Sans', sans-serif  ;
    }
}


:lang(ar) {
    body {
        font-family: 'Cairo', sans-serif !important;
    }
    label,
    button,
    select,
    input{
        padding:5px;
        font-size:16px;
        font-family: 'Cairo', sans-serif !important;
    }
    text-align: right;
    .text-left {
        text-align: right;
    }
    .text-right {
        text-align: left;
    }
    .btn {
        text-align: center;
    }
    .border-right {
        border-left: 1px solid #dee2e6!important;
    }
    @media (min-width: 768px){ 
        .text-md-left {
            text-align: right!important;
        }
    }
    .ml-auto {
        margin-right: auto!important;
        margin-left: unset !important;
    }
     
    .Coursers .Coursers-item .price {
        top: 130px;
        left: 20px;
        right: unset;
        
    }
    .MuiStepConnector-alternativeLabel {
        left: calc(50% + 20px) ;
        right: calc(-50% + 20px)  ;
    }
    .offset-md-1 {
        margin-right: 8.333333%;
        margin-left: unset;
    }
    .float-left {
        float: right !important;
    }
    .float-right {
        float: left !important;
    }
  
}


@media (prefers-color-scheme: light) {
    .day.light-scheme   { background: white; color:  #555; }
    .night.light-scheme { background:  #eee; color: black; }
  }
  
@media (prefers-color-scheme: dark) {
    .day.dark-scheme   { background:  #333; color: white; }
    .night.dark-scheme { background: black; color:  #ddd; }
  }
  
  