.footer {
    background: rgb(0,212,255);
    background: linear-gradient(45deg, rgba(0,212,255,1) 0%, rgba(59,149,203,1) 100%);
    width: 100%;
    .center-footer {
        img {
            width: 100px;
        }
    }
    .social {
        ul {
            li {
                a {
                    color: #fff;
                    img {
                        border: 1px solid transparent;
                        transition: all .5s ease-in-out;
                        &:hover {
                            border-radius: 50%;
                            border: 1px solid #fff;
                        }
                    }
                }
            }
        }
    }
    .site-map {
        ul {
            li {
                a {
                    transition: all .5s ease-in-out;
                    color: #ddd;
                    &:hover {
                        color: #fff;
                        transition: all .5s ease-in-out;
                    }
                   
                }
            }
        }
    }
    .copy-right {
        font-size: 14px;
    }
}