.OnlineReg {
  line-height: 38px;
  min-height: 100vh;
  .contactPathRight {
    position: absolute;
    top: -100px;
    right: -200px;
    z-index: -1;
    //  mobile
    @media (max-width: 576px) {
      display: none;
    }
  }
  .contactPathLeft {
    position: absolute;
    top: -150px;
    left: -250px;
    z-index: -1;
    //  mobile
    @media (max-width: 576px) {
      display: none;
    }
  }
  .nav-pills {
    background: #fff;
    filter: drop-shadow(0px 40px 50px rgba(138, 149, 158, 0.14));
  }
  .custme-popup {
    filter: drop-shadow(0px 40px 50px rgba(138, 149, 158, 0.14));
  }
 
  .nav-pills .nav-link {
    border-radius: .25rem;
    cursor: pointer;
    &:hover {
      color: #3b95cb;
    }
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #3b95cb;
  color: #fff;
  cursor: pointer;
}
  .StudySystem {
    .carousel-inner {
      height: 395px;
      position: relative;
      @media (max-width: 576px) {
        height: 300px;
      }
      img {
        border-radius: 20px;
        @media (max-width: 576px) {
          height: 300px;
        }
      }
      .overlay-image {
        background-color: rgba(2, 2, 2, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
    .parent-overlay {
      position: relative;

      img {
        border-radius: 20px;
      }
      .overlay-image {
        background-color: rgba(2, 2, 2, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        p {
          line-height: 38px;
        }
      }
    }
  }
  .advice {
    line-height: 42px;
  }
  .StudySystem {
    .note {
      position: relative;
      width: 100%;
      background: url(../../image/bg_note.svg) no-repeat;
      height: 210px;

      @media (max-width: 576px) {
        height: auto;
      }
      .content-note {
        h5 {
          line-height: 50px;
          @media (max-width: 576px) {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }
  }
  .PaymentMethod {
    .item {
      height: 280px;
      cursor: pointer;
      border-radius: 15px;
      background: #fff;
      box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
      @media (max-width: 576px) {
        height: 150px;
      }
      img {
        padding: 20px;
      }
      &:hover {
        box-shadow: 0px 14px 60px rgba(0, 0, 0, 0.07);
      }
    }
    .note {
      position: relative;
      width: 100%;
      background: url(../../image/bg_note_payment.svg) no-repeat;
      height: 210px;

      @media (max-width: 576px) {
        height: auto;
      }
      .content-note {
        h4 {
          line-height: 50px;
          padding-top: 30px;
          @media (max-width: 576px) {
            padding-top: 30px;
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }
  }
  .PaymentMethodEG {
    .item {
      cursor: pointer;
      height: 340px;
      border-radius: 15px;
      background: #fff;
      box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
      @media (max-width: 576px) {
        height: auto;
        padding-bottom: 20px;
      }
      img {
        padding: 20px;
      }
      &:hover {
        box-shadow: 0px 14px 60px rgba(0, 0, 0, 0.07);
      }
    }
    .note-payment-eg {
      position: relative;
      width: 100%;
      background: url(../../image/bg_note.svg) no-repeat;
      height: 170px;

      @media (max-width: 576px) {
        height: auto;
      }
      .content-note {
        h5 {
          line-height: 50px;
          @media (max-width: 576px) {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }
  }
  .swiper-slide-active {
    transform: translate3d(-150px, 0px, 0px) rotateX(0deg) rotateY(0deg) !important;
  }
  .swiper-slide-next {
    transform: translate3d(105px, 0px, -100px) rotateX(0deg) rotateY(40deg) !important;
  }
  .swiper-slide {
    img {
      height: 400px;
    }
  }
  .card-group {
    .card {
      border-radius: 10px;
      height: 397px;
      background: #fff;
      filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
      border: none;
      h2 {
        position: relative;
        small {
          font-size: 14px;
        }
        .line-discount {
          position: absolute;
          content: "";
          height: 50px;
          width: 1px;
          display: inline-block;
          top: -3px;
          left: 120px;
          color: #000;
          background-color: #000;
          transform: rotate(45deg);
        }
      }
      .font-main {
        color: #6c63ff;
      }
      .font-discount {
        color: #fd3e6c;
      }
    }
  }
  .table {
    height: 354.5px;
    background: #fff;
    filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
    border-radius: 20px;
  }
}

.btn-info {
  height: 55px;
  background: linear-gradient(#3b95cb 0%, #00d4ff 100%);
}
.btn-warning {
  height: 55px;
  background: linear-gradient(#f0d819 0%, #fdbe03 100%);
}
.btn-light {
  height: 55px;
  background: linear-gradient(#fb7cdc 0%, #eb42ff 100%);
}
.btn-danger {
  height: 55px;
  background: linear-gradient(#fd3f98 0%, #fd3e4f 100%);
}

.color-info {
  color: #3b95cb;
}
.color-warning {
  color: #fdbe03;
}
.color-light {
  color: #eb42ff;
}
.color-danger {
  color: #fd3e4f;
}

.button-reg-online {
  position: fixed;
  top: 200px;
  left: -60px;
  transform: rotate(90deg);
  width: 200px;
  background: linear-gradient(#f0d819 0%, #fdbe03 100%);
}

.PrevStudents {
  img {
    height: 300px;
    width: 100%;
    border-radius: 20px;
  }
}
html {
  scroll-behavior: smooth;
}


.item-table {
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
  background-color: #fff;
}