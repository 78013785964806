.joinUs {
    margin-top: 50px;
    @media (max-width: 576px) {
        margin-top: 80px;
    }
    .join-item {
        border-radius: 10px;
        height: 400px;
        transition: all .5s ease-in-out;
        position: relative;
        -webkit-box-shadow: 2px 7px 27px -4px rgba(204,204,204,1);
        -moz-box-shadow: 2px 7px 27px -4px rgba(204,204,204,1);
        box-shadow: 2px 7px 27px -4px rgba(204,204,204,1);
        &:hover {
            transition: all .5s ease-in-out;
        }
        @media (max-width: 576px) {
                height: auto;
        }
    }
}