.Branches {
    position: relative;
    .header-photo-gallery {
        background:  url(../../../image//bg_gallery.svg) no-repeat center center;
        height: 100px;
        width: 100%;
    }
    .Branches-item {
        position: relative;
        img {
            height: 150px;
            width: 100%;
            border-radius: 10px;
            -webkit-box-shadow: 3px 3px 22px 0px rgba(51,51,51,0.2);
            -moz-box-shadow: 3px 3px 22px 0px rgba(51,51,51,0.2);
            box-shadow: 3px 3px 22px 0px rgba(51,51,51,0.2);
        }
        .overlay-desc {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: rgba($color: #000000, $alpha: .7);
            padding: 5px 10px;
            color: #fff;
            border-radius: 0 0 10px 10px;
            h6 {
                font-weight: normal;
            }
        }

    }
   
}