.ContactUs {
    min-height: 500px;
    .Coursers-item-path {
        transition: all .5s ease-in-out;
        cursor: pointer;
        position: relative;
        transition: all .5s ease-in-out;
        color: #fff;
        .desc-res-item {
            width: 200px;
            position: absolute;
            top: 20%;
            left: 30px;
        }
    }
    .Coursers-item-path {
        background: url(../../image/bg_courese_path.svg) no-repeat center center ;
        transition: all .5s ease-in-out;
        border-radius: 10px;
        height: 205px;
        width: 100%;
        background-size: cover;
    }
    .Coursers-item-path-student {
        background: url(../../image/bg_courese_path2.svg) no-repeat center center ;
        transition: all .5s ease-in-out;
        border-radius: 10px;
        height: 205px;
        width: 100%;
        background-size: cover;

    }
    .contact-form {}

}

.top-desc {
    background: rgb(0,212,255);
    background: linear-gradient(45deg, rgba(0,212,255,1) 0%, rgba(59,149,203,1) 100%);
}


// raido button
.z-radio {
    width: 100%;
    display: block;
    margin: 20px 0;
    margin-right: 0;
    margin-bottom: 0;
    input[type="radio"] {
      appearance: none;
      width: 100%;
      &:checked + label {
        &:before {
          border-color: #3B95CB;
        }
        &:after {
          transform: scale(1);
        }
      }
    }
    label {
      width: 100%;
      //height: 170px;
      position: relative;
      cursor: pointer;
    //   padding-right: 30px;
      font-weight: bold;
      color: #555;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        z-index: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transform: scale(0);
        border: 1px solid #3B95CB ;
        border-radius: 10px;
        transition: all .3s ease-in-out;
      }
    }
  }