.AboutUs {
    position: relative;
   .header-ahout {
    background: url(../../image/bg_about.svg) no-repeat center center ;
    position: relative;
    height: 350px;
   }
   video {
       height: 300px;
   }
    .Polygon_back {
        position: absolute;
        right: -330px;
        top: 30%;
    }
    .Polygon_front {
        position: absolute;
        left: -330px;
        top: 50%;
    }
}