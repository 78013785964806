.BranchesPage {
  min-height: 780px;
  position: relative;
  .BranchesPageSummary {
    transition: all .5s ease-in-out;
  }
  h2 {
    img {
      width: 48px;
    }
  }
  .image-branch {
    height: 235px;
  }
  .maps {
    height: 350px;
  }
  .BranchesPageSummary:hover {
    -webkit-box-shadow: 6px 6px 27px 0px rgba(55,55,55,0.2);
    -moz-box-shadow: 6px 6px 27px 0px rgba(55,55,55,0.2);
    box-shadow: 6px 6px 27px 0px rgba(55,55,55,0.2);
    transition: all .5s ease-in-out;
  }
  .nav-link {
    border: 1px solid transparent;
  }
  .nav-link:hover {
    cursor: pointer;
    border: 1px solid #ccc;
  }
  @media( max-width: 768px ) {
    .nav-link {
      border: 1px solid #ccc;
      margin-bottom : 0;
    }
  }
    .clip-path-right {
        position: absolute;
        right: -109px;
        top: -9%;
        z-index: -1;
    }
   
    .Coursers-item-path {
      background: url(../../image/bg_courese_path.svg) no-repeat center center ;
      height: 205px;
      width: 100%;
      background-size: cover;
      border-radius: 10px;
      position: relative;
      transition: all .5s ease-in-out;
      color: #fff;
      &:hover {
          box-shadow: 5px 5px rgba(rgba(59, 149, 203, 1), 0.4),
          10px 10px rgba(rgba(59, 149, 203, 1), 0.3),
          15px 15px rgba(rgba(59, 149, 203, 1), 0.733),
          20px 20px rgba(rgba(59, 149, 203, 1), 0.1),
          25px 25px rgba(rgba(59, 149, 203, 1), 0.05);
          color: #fff;
          transition: all 0.3s cubic-bezier(.25,.8,.25,1);
      }
      .desc-res-item {
          width: 200px;
          position: absolute;
          top: 30%;
          left: 30px;
      }
  }
}